// import React, { Component } from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import { Link } from "react-router-dom";
// import Projects from "../../api/project";
// import Projects2 from "../../api/projects2";
// import { useParams } from "react-router-dom";

// import { Service2I18n } from "../../i18n/service2";

// class ProjectSection extends Component {
//   render() {
//     var settings = {
//       dots: false,
//       arrows: true,
//       speed: 1000,
//       slidesToShow: 3,
//       slidesToScroll: 1,
//       autoplay: true,
//       responsive: [
//         {
//           breakpoint: 1024,
//           settings: {
//             slidesToShow: 2,
//             slidesToScroll: 1,
//             infinite: true,
//           },
//         },
//         {
//           breakpoint: 991,
//           settings: {
//             slidesToShow: 2,
//             slidesToScroll: 1,
//           },
//         },
//         {
//           breakpoint: 767,
//           settings: {
//             slidesToShow: 1,
//             slidesToScroll: 1,
//           },
//         },
//         {
//           breakpoint: 480,
//           settings: {
//             slidesToShow: 1,
//             slidesToScroll: 1,
//           },
//         },
//       ],
//     };

//     const ClickHandler = () => {
//       window.scrollTo(10, 0);
//     };

//     return (
//       <div className={`wpo-project-area ${this.props.pClass}`}>
//         <div className="container-fluid">
//           <div className="row align-items-center">
//             <div className="wpo-project-wrap project-active owl-carousel">
//               <Slider {...settings}>
//                 {Projects2.slice(0, 10).map((project2, pot) => (
//                   <div className="wpo-project-item" key={pot}>
//                     <div className="wpo-project-img">
//                       <img src={project2.pImg} alt="" />
//                       <div className="left-border"></div>
//                       <div className="right-border"></div>
//                     </div>
//                     <div className="wpo-project-text">
//                       <h2>
//                         <Link
//                           onClick={ClickHandler}
//                           to={`/project2-single/${project2.Id}`}
//                         >
//                           {project2.title}
//                         </Link>
//                       </h2>
//                       <span>{project2.subTitle}</span>
//                     </div>
//                   </div>
//                 ))}
//               </Slider>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }

// export default ProjectSection;

// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< new one >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>



import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";

import { ProjectsI18n } from "../../i18n/projects";

const ProjectSection = () => {
  const { lang = "ka" } = useParams();

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  var settings = {
    dots: false,
    arrows: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (

    <div className={`wpo-project-area `}>
    <div className="container-fluid">
      <div className="row align-items-center">
        <div className="wpo-project-wrap project-active owl-carousel">
          <Slider {...settings}>
            {ProjectsI18n.map((pot) => (
              <div className="wpo-project-item" key={pot.id}>
                <div className="wpo-project-item">
                  <div className="wpo-project-img">
                    <img
                      src={pot.data[lang].pImg}
                      alt=""
                      style={{ aspectRatio: 422.45 / 316.83 }}
                    />
                    <div className="left-border"></div>
                    <div className="right-border"></div>
                  </div>
                  <div className="wpo-project-text">
                    <h2>
                      <Link
                        onClick={ClickHandler}
                        to={`/${lang}/service-single/${pot.id}`}
                      >
                        {pot.data[lang].title}
                      </Link>
                    </h2>
                    <span>{pot.data[lang].subTitle}</span>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
    </div>
  );
};
export default ProjectSection;