export const AboutI18n = {
  ka: {
    text1: `დეკოლაინი მომხმარებელს სთავაზობს მომსახურების სრულ პაკეტს`,

    text2: `სამშენებლო-სარემონტო კომპანია „დეკოლაინი“ 2016 წელს დაარსდა და ამ მიმართულებით მალევე იქცა სექტორის ლიდერად. კომპანია მუდმივად ორიენტირებულია ზრდაზე, განვითარებაზე და აქვს ამბიცია იკავებდეს ყველაზე დახვეწილი, ორგანიზებული, პროფესიონალებით დაკომპლექტებული გუნდის პოზიციას. დეკოლაინის ძირითადი საქმიანობა ინდივიდუალური სახლების, სხვადასხვა კომპანიის საოფისე თუ საწარმოო ფართების , მრავალსართულიანი საცხოვრებელი სახლების მშენებლობა და რემონტია.`,

    text3: `კომპანია საქართველოში ახალი სამშენებლო ტექნოლოგიების გამოყენებას ნერგავს. ჩვენი დიზაინერები კი ნოვატორულ პროექტებსა და თანამედროვე სტილის დიზაინს გთავაზობენ.

`,

    aboutUs:`
        <p style="line-height:1.295;margin-bottom:12pt;margin-top:12pt;" dir="ltr"><span style="background-color:#ffffff;color:#000000;font-family:'Times New Roman',serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">სამშენებლო-სარემონტო კომპანია „დეკოლაინი“ 2016 წელს დაარსდა და ამ მიმართულებით მალევე იქცა სექტორის ლიდერად. კომპანია მუდმივად ორიენტირებულია ზრდაზე, განვითარებაზე და აქვს ამბიცია იკავებდეს ყველაზე დახვეწილი, ორგანიზებული, პროფესიონალებით დაკომპლექტებული გუნდის პოზიციას. დეკოლაინის ძირითადი საქმიანობა ინდივიდუალური სახლების, სხვადასხვა კომპანიის საოფისე თუ საწარმოო ფართების , მრავალსართულიანი საცხოვრებელი სახლების მშენებლობა და რემონტია.</span></span></p>
        <p>&nbsp;</p>
        <p style="line-height:1.295;margin-bottom:12pt;margin-top:12pt;" dir="ltr"><span style="background-color:transparent;color:#000000;font-family:'Times New Roman',serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">&nbsp;</span></span><span style="background-color:transparent;color:#000000;font-family:Calibri,sans-serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">●</span><span class="Apple-tab-span" style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre;"> </span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">მცირე და მრავალფუნქციური საცხოვრებელი სახლების და აგარაკების მშენებლობა მაღალი ხარისხის მასალების გამოყენებით და ოპტიმალური დანახარჯით</span></span></p>
        <p style="line-height:1.295;margin-bottom:8pt;margin-top:0pt;" dir="ltr"><span style="background-color:transparent;color:#000000;font-family:Calibri,sans-serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">●</span><span class="Apple-tab-span" style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre;"> </span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">სარემონტო სამუშაოები, როგორც ინდივიდუალური ბინის, ასევე კომერციული ობიექტისთვის</span></span></p>
        <p style="line-height:1.295;margin-bottom:8pt;margin-top:0pt;" dir="ltr"><span style="background-color:transparent;color:#000000;font-family:Calibri,sans-serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">●</span><span class="Apple-tab-span" style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre;"> </span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">სრული არქიტექტურული პროექტი , როგორც საცხოვრებელი სახლების, ასევე სხვა დანიშნულების შენობა ნაგებობების, პროექტის შესათანხმებლად საჭირო დოკუმენტაცია</span></span></p>
        <p style="line-height:1.295;margin-bottom:8pt;margin-top:0pt;" dir="ltr"><span style="background-color:transparent;color:#000000;font-family:Calibri,sans-serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">●</span><span class="Apple-tab-span" style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre;"> </span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">ინტერიერის ინდივიდუალური დიზაინის პროექტი, მის შესასრულებლად საჭირო ყველა ეტაპი.</span></span></p>
        <p style="line-height:1.295;margin-bottom:8pt;margin-top:0pt;" dir="ltr"><span style="background-color:transparent;color:#000000;font-family:Calibri,sans-serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">●</span><span class="Apple-tab-span" style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre;"> </span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">ექსტერიერის დიზაინი.</span></span></p>
        <p>&nbsp;</p>
        <p style="line-height:1.295;margin-bottom:8pt;margin-top:0pt;" dir="ltr"><span style="background-color:transparent;color:#000000;font-family:Calibri,sans-serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">კომპანია საქართველოში ახალი სამშენებლო ტექნოლოგიების გამოყენებას ნერგავს. ჩვენი დიზაინერები კი ნოვატორულ პროექტებსა და თანამედროვე სტილის დიზაინს გთავაზობენ.</span></span></p>
        <p style="line-height:1.295;margin-bottom:8pt;margin-top:0pt;" dir="ltr"><span style="background-color:transparent;color:#000000;font-family:Calibri,sans-serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">მაღალკვალიფიციური კადრებით დაკომპლექტებული კომპანია ყველა პროცესს უფრო კომფორტულსა და მოსახერხებელს ხდის.</span></span></p>
    `,

    text4:`აღმოაჩინე მეტი`,
  
    text5:`ჩვენს შესახებ`,
  
  
  },
  en: {
    text1: `Decoline offers customers a complete package of services `,

    text2: `The construction and repair company "Decoline" was founded in 2016 and soon became the leader of the sector in this direction. The company is constantly focused on growth, development and has the ambition to occupy the position of the most sophisticated, organized, professional team. Decoline offers customers a full package of services: architecture, design, construction and repair/Commissioning. Decoline's portfolio includes the construction and renovation of hundreds of private residential apartments, individual houses, office or production spaces of various companies, and multi-storey residential buildings.
    .`,

    text3: `Decoline offers customers  complete set  of services: architecture, design, construction and renovation/commissioning. Decoline's portfolio includes the construction and renovation of hundreds of private residential apartments, individual houses, office or production spaces of various companies,  construction and renovation of multi-storey residential buildings.
    `,


    text4:`Discover More`,

    text5:`About`,

    aboutUs:`
        <p style="line-height:1.295;margin-bottom:12pt;margin-top:12pt;" dir="ltr"><span style="background-color:#ffffff;color:#000000;font-family:'Times New Roman',serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">სამშენებლო-სარემონტო კომპანია „დეკოლაინი“ 2016 წელს დაარსდა და ამ მიმართულებით მალევე იქცა სექტორის ლიდერად. კომპანია მუდმივად ორიენტირებულია ზრდაზე, განვითარებაზე და აქვს ამბიცია იკავებდეს ყველაზე დახვეწილი, ორგანიზებული, პროფესიონალებით დაკომპლექტებული გუნდის პოზიციას. დეკოლაინის ძირითადი საქმიანობა ინდივიდუალური სახლების, სხვადასხვა კომპანიის საოფისე თუ საწარმოო ფართების , მრავალსართულიანი საცხოვრებელი სახლების მშენებლობა და რემონტია.</span></span></p>
        <p>&nbsp;</p>
        <p style="line-height:1.295;margin-bottom:12pt;margin-top:12pt;" dir="ltr"><span style="background-color:transparent;color:#000000;font-family:'Times New Roman',serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">&nbsp;</span></span><span style="background-color:transparent;color:#000000;font-family:Calibri,sans-serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">●</span><span class="Apple-tab-span" style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre;"> </span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">მცირე და მრავალფუნქციური საცხოვრებელი სახლების და აგარაკების მშენებლობა მაღალი ხარისხის მასალების გამოყენებით და ოპტიმალური დანახარჯით</span></span></p>
        <p style="line-height:1.295;margin-bottom:8pt;margin-top:0pt;" dir="ltr"><span style="background-color:transparent;color:#000000;font-family:Calibri,sans-serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">●</span><span class="Apple-tab-span" style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre;"> </span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">სარემონტო სამუშაოები, როგორც ინდივიდუალური ბინის, ასევე კომერციული ობიექტისთვის</span></span></p>
        <p style="line-height:1.295;margin-bottom:8pt;margin-top:0pt;" dir="ltr"><span style="background-color:transparent;color:#000000;font-family:Calibri,sans-serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">●</span><span class="Apple-tab-span" style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre;"> </span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">სრული არქიტექტურული პროექტი , როგორც საცხოვრებელი სახლების, ასევე სხვა დანიშნულების შენობა ნაგებობების, პროექტის შესათანხმებლად საჭირო დოკუმენტაცია</span></span></p>
        <p style="line-height:1.295;margin-bottom:8pt;margin-top:0pt;" dir="ltr"><span style="background-color:transparent;color:#000000;font-family:Calibri,sans-serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">●</span><span class="Apple-tab-span" style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre;"> </span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">ინტერიერის ინდივიდუალური დიზაინის პროექტი, მის შესასრულებლად საჭირო ყველა ეტაპი.</span></span></p>
        <p style="line-height:1.295;margin-bottom:8pt;margin-top:0pt;" dir="ltr"><span style="background-color:transparent;color:#000000;font-family:Calibri,sans-serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">●</span><span class="Apple-tab-span" style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre;"> </span><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">ექსტერიერის დიზაინი.</span></span></p>
        <p>&nbsp;</p>
        <p style="line-height:1.295;margin-bottom:8pt;margin-top:0pt;" dir="ltr"><span style="background-color:transparent;color:#000000;font-family:Calibri,sans-serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">კომპანია საქართველოში ახალი სამშენებლო ტექნოლოგიების გამოყენებას ნერგავს. ჩვენი დიზაინერები კი ნოვატორულ პროექტებსა და თანამედროვე სტილის დიზაინს გთავაზობენ.</span></span></p>
        <p style="line-height:1.295;margin-bottom:8pt;margin-top:0pt;" dir="ltr"><span style="background-color:transparent;color:#000000;font-family:Calibri,sans-serif;font-size:13.999999999999998pt;"><span style="font-style:normal;font-variant:normal;font-weight:400;text-decoration:none;vertical-align:baseline;white-space:pre-wrap;">მაღალკვალიფიციური კადრებით დაკომპლექტებული კომპანია ყველა პროცესს უფრო კომფორტულსა და მოსახერხებელს ხდის.</span></span></p>
    `,
  },
};
