export const ParnersData =[
    {
        image:require('./Picture1.jpg')
    },
    {
        image:require('./Picture2.png')
    },
    {
        image:require('./Picture3.png')
    },
    {
        image:require('./Picture4.jpg')
    },
    {
        image:require('./Picture5.png')
    },
    {
        image:require('./Picture6.jpg')
    },
    {
        image:require('./Picture7.png')
    },
    {
        image:require('./Picture8.png')
    },
    {
        image:require('./Picture9.png')
    },
    {
        image:require('./Picture10.jpg')
    },
    {
        image: require("./Picture11.jpg")
    },
    {
        image:require('./Picture12.png')
    },
    {
        image:require('./Picture13.jpg')
    },
    {
        image:require('./Picture14.jpg')
    },
    {
        image:require('./Picture15.jpg')
    },
    {
        image:require('./Picture16.jpg')
    },
    {
        image:require('./Picture17.jpg')
    },
    {
        image:require('./Picture18.png')
    }
]