import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import {Autoplay, Navigation, Pagination} from 'swiper/modules';
import {ParnersData} from "../../images/partners/ParnersData";
import {useParams} from "react-router-dom";
import SectionTitle from "../SectionTitle";
import {Service2I18n} from "../../i18n/service2";

export default function Partners() {
    const { lang = "ka" } = useParams();

    return (
        <div className="wpo-about-area-s2 section-padding">
            <div className="container">
                <SectionTitle subTitle={Service2I18n[lang]['partners']} />

                <div className="row align-items-center">
                    <Swiper
                        slidesPerView={8}
                        spaceBetween={10}
                        pagination={{
                            clickable: true,
                        }}
                        autoplay={{
                            "delay": 2500,
                            "disableOnInteraction": false
                        }}
                        navigation={true}

                        loop={true}
                        breakpoints={{
                            '@0.00': {
                                slidesPerView: 2,
                                spaceBetween: 10,
                            },
                            '@0.75': {
                                slidesPerView: 3,
                                spaceBetween: 20,
                            },
                            '@1.00': {
                                slidesPerView: 5,
                                spaceBetween: 40,
                            },
                            '@1.50': {
                                slidesPerView: 7,
                                spaceBetween: 50,
                            },
                        }}
                        modules={[Pagination, Autoplay, Navigation]}
                        className="mySwiper"
                    >
                        {
                            ParnersData.map((partner,index)=>{
                                return (
                                    <SwiperSlide key={index}>
                                        <div style={{flex:1,justifyItems:'center',justifyContent:'center',display:'flex'}}>
                                            <img src={partner?.image}/>
                                        </div>
                                    </SwiperSlide>
                                )
                            })
                        }

                    </Swiper>
                </div>
            </div>
        </div>
    );
}
