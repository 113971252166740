import React from 'react'
import { Link } from 'react-router-dom'
import VideoModal from '../../components/ModalVideo'
import abimg from '../../images/about11.png'
import abimg2 from '../../images/about12.jpg'


const About2 = (props) => {
    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }
    return (
        <div className="wpo-about-area-s2 section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="wpo-about-img">
                            <div className="wpo-about-img-left">
                                <img src={abimg2} alt=""/>
                                {/* <div className="wpo-about-img-text">
                                        <h2>25+</h2>
                                        <p>Years of Experience</p>
                                    </div> */}
                            </div>
                            <div className="wpo-about-img-right">
                                <img src={abimg} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-about-title">
                                <span>ჩვენ შესახებ</span>
                                {/* <h2>We Offer You Profesional Interior Design</h2> */}
                            </div>
                            {/* <p>სამშენებლო-სარემონტო კომპანია „დეკოლაინი“  საქართველოს ბაზარზე 2016 წლიდან ოპერირებს. კომპანიის საქმიანობა ფართო სპექტრულია და გთავაზობთ მრავალმხრივ მომსახურებას.
</p> */}
<br/>
                            <ul className="ab-list">
                                <li>მცირე და მრავალფუნქციური საცხოვრებელი სახლების და აგარაკების მშენებლობა მაღალი ხარისხის მასალების გამოყენებით და ოპტიმალური დანახარჯით</li>
                                <li>სარემონტო სამუშაოები, როგორც ინდივიდუალური ბინის, ასევე კომერციული ობიექტისთვის</li>
                                <li>სრული არქიტექტურული პროექტი , როგორც საცხოვრებელი სახლების, ასევე სხვა დანიშნულების შენობა ნაგებობების, პროექტის შესათანხმებლად საჭირო დოკუმენტაცია</li>
                                <li>ინტერიერის ინდივიდუალური დიზაინის პროექტი, მის შესასრულებლად საჭირო ყველა ეტაპი.</li>
                                <li>ექსტერიერის დიზაინი.</li>    
                            </ul>
                            <div className="btns">
                                <Link to="/about" onClick={ClickHandler} className="theme-btn">დეტალურად</Link>
                                <ul>
                                    <li className="video-holder">
                                        <VideoModal />
                                    </li>
                                    <li className="video-text">
                                        ვიდეო
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About2;