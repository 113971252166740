import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/decoline.logo2.png";
import Projects from "../../api/project";
import { useParams } from "react-router-dom";

import { ProjectsI18n } from "../../i18n/projects";
import { ContactI18n } from "../../i18n/contact";
import { ServiceI18n } from "../../i18n/service";
import { Service2I18n } from "../../i18n/service2";

const Footer = (props) => {
  const { lang = "ka" } = useParams();

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <footer className={`wpo-site-footer ${props.ftClass}`}>
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <Link
                    onClick={ClickHandler}
                    className="logo"
                    to="`/${lang}/home`"
                  >
                    <img src={Logo} alt="" style={{filter:'invert(1)'}} />
                  </Link>
                </div>
                {/* <p>
                  სამშენებლო-სარემონტო კომპანია „დეკოლაინი“ 2016 წელს დაარსდა და
                  ამ მიმართულებით მალევე იქცა სექტორის ლიდერად.
                </p> */}
                <ul>
                  <li>
                    <Link
                      onClick={ClickHandler}
                      to="https://www.facebook.com/DecoLine.ge/"
                    >
                      <i className="ti-facebook"></i>
                    </Link>
                  </li>

                  <li>
                    <Link
                      onClick={ClickHandler}
                      to="https://www.instagram.com/decoline.ge/"
                    >
                      <i className="ti-instagram"></i>
                    </Link>
                  </li>

                  <li>
                    <Link
                      onClick={ClickHandler}
                      to="https://www.linkedin.com/company/decoline-llc-%E1%83%93%E1%83%94%E1%83%99%E1%83%9D%E1%83%9A%E1%83%90%E1%83%98%E1%83%9C%E1%83%98/"
                    >
                      <i className="ti-linkedin"></i>
                      
                    </Link>
                  </li>
                  {/* <li>
                    <Link onClick={ClickHandler} to="`info@decoline.ge">
                      <i className="ti-google"></i>
                    </Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                <h3>{Service2I18n[lang]["text2"]}</h3>
                </div>
                {/* <ul>
                  
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      არქიტექტურა
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      მშენებლობა
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      დიზაინი
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      რემონტი
                    </Link>
                  </li>
                </ul> */}
                <ul>
                    {ServiceI18n.map((sitem) => (
                            <li key={sitem.id}><Link onClick={ClickHandler} to={`/${lang}/service-single/${sitem.id}`}>{sitem.data[lang].sTitle}</Link></li>
                        ))}
                    </ul>
              </div>
            </div>
            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget wpo-service-link-widget">
                <div className="widget-title">
                  <h3>{ContactI18n[lang]["text6"]} </h3>
                </div>
                <div className="contact-ft">
                  <ul>
                    <li>
                      <i className="fi flaticon-location"></i>
                      {ContactI18n[lang]["text2"]}
                      <br />
                    </li>
                    {/* <li>
                      <i className="fi flaticon-location"></i>{ContactI18n[lang]["text3"]}

                    </li> */}
                    <li>
                      <i className="fi flaticon-telephone"></i>  0322 04 20 20
                      <br />
                    </li>
                    <li>
                      <i className="fi flaticon-email"></i>info@decoline.ge
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col col-xl-3  col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget instagram">
                <div className="widget-title">
                  <h3>Our Gallery</h3>
                </div>
                <ul className="d-flex">
                  {ProjectsI18n.slice(0, 3).map((pot) => (
                    <li key={pot.id}>
                      <Link
                        onClick={ClickHandler}
                        to={`/${lang}/finished/${pot.id}`}
                      >
                        <img src={pot.data[lang].pImg} alt="" />
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*<div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <ul>
                <li>
                  &copy; 2022 Arkio Template. Design By{" "}
                  <Link onClick={ClickHandler} to="/404">
                    wpOceans
                  </Link>
                  . All Rights Reserved.
                </li>
                <li>
                  <Link onClick={ClickHandler} to="/404">
                    Terms of use |
                  </Link>{" "}
                  <Link onClick={ClickHandler} to="/404">
                    Privacy Environmental Policy
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>*/}
    </footer>
  );
};

export default Footer;
