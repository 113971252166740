import React from 'react'
import CountUp from "react-countup";


const FunFact = (props) => {

    const funfact = [
        {
            title: 10,
            append: "+",
            subTitle: ' წლიანი გამოცდილება',
        },
        {
            title: 80,
            subTitle: 'დასაქმებული სპეციალისტი',
        },
        {
            title: 500,
            append: "+",
            subTitle: 'წარმატებულად შესრულებული პროექტი',
        },
        {
            title: 453,
            append: "+",
            subTitle: 'კმაყოფილი მომხმარებელი',
        },


    ]


    return (

        <section className={`wpo-fun-fact-section pb-120 ${props.fnClass}`}>
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="wpo-fun-fact-grids clearfix">
                            {funfact.map((funfact, fitem) => (
                                <div className="grid" key={fitem}>
                                    <div className="info">
                                        <h3>
                                            <CountUp end={funfact.title} duration={3} /> { "  "}
                                            {funfact?.append && <span style={{fontSize:'2.6rem',marginLeft:10}}> + </span>}
                                        </h3>
                                        <p>{funfact.subTitle}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FunFact;