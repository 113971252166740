import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from '../../images/slider/1-slide.jpg'
import hero2 from '../../images/slider/2-slide.jpg'
import hero3 from '../../images/slider/3-slide.png'
import hero4 from '../../images/slider/4-slide.jpg'



class Hero2 extends Component {
    render() {

        var settings = {
            dots: false,
            arrows: true,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            fade: true
        };

        return (
            <section className="wpo-box-style">
                <div className={`wpo-hero-slider wpo-hero-style-2 ${this.props.heroClass}`}>
                    <div className="hero-container">
                        <div className="hero-wrapper">
                            <Slider {...settings}>
                                <div className="hero-slide">
                                    <div className="slide-inner" style={{backgroundImage: `url(${hero1})`}}>
                                        <div className="container-fluid">
                                            <div className="slide-content">
                                                <div className="slide-title">
                                                    <h2>მშენებლობა</h2>
                                                </div>
                                                <div className="slide-title-sub">
                                                    {/* <p>მცირე და მრავალფუნქციური საცხოვრებელი სახლების და აგარაკების მშენებლობა მაღალი ხარისხის მასალების გამოყენებით და ოპტიმალური დანახარჯით</p> */}
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="slide-btns">
                                                    <Link to="/service-single/2" className="theme-btn">დეტალურად</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="hero-slide">
                                    <div className="slide-inner" style={{backgroundImage: `url(${hero2})`}}>
                                        <div className="container-fluid">
                                            <div className="slide-content">
                                                <div className="slide-title">
                                                    <h2>რემონტი</h2>
                                                </div>
                                                <div className="slide-title-sub">
                                                    {/* <p>სარემონტო სამუშაოები, როგორც ინდივიდუალური ბინის, ასევე კომერციული ობიექტისთვის</p> */}
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="slide-btns">
                                                    <Link to="/service-single/4" className="theme-btn">დეტალურად</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="hero-slide">
                                    <div className="slide-inner" style={{backgroundImage: `url(${hero3})`}}>
                                        <div className="container-fluid">
                                            <div className="slide-content">
                                                <div className="slide-title">
                                                    <h2>არქიტექტურა </h2>
                                                </div>
                                                <div className="slide-title-sub">
                                                    {/* <p>ინტერიერის ინდივიდუალური დიზაინის პროექტი, მის შესასრულებლად საჭირო ყველა ეტაპი.</p> */}
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="slide-btns">
                                                    <Link to="/service-single/3" className="theme-btn">დეტალურად</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="hero-slide">
                                    <div className="slide-inner" style={{backgroundImage: `url(${hero4})`}}>
                                        <div className="container-fluid">
                                            <div className="slide-content">
                                                <div className="slide-title">
                                                    <h2>ინტერიერის დიზაინი </h2>
                                                </div>
                                                <div className="slide-title-sub">
                                                    {/* <p>ინტერიერის ინდივიდუალური დიზაინის პროექტი, მის შესასრულებლად საჭირო ყველა ეტაპი.</p> */}
                                                </div>
                                                <div className="clearfix"></div>
                                                <div className="slide-btns">
                                                    <Link to="/service-single/3" className="theme-btn">დეტალურად</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Hero2;