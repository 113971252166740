import { useParams, useNavigate } from "react-router-dom";
import kaFlag from "../../images/language/ka.png";
import enFlag from "../../images/language/en.png";

export const LanguageSwitcher = () => {
  const navigate = useNavigate();
  const { lang = "ka" } = useParams();

  return (
    <div 
        style={{
            cursor:"pointer",
        }}
      onClick={() => {
        let urlParams = window.location.pathname.split("/");
        urlParams[1] = urlParams[1] === "en" ? "ka" : "en";
        navigate(urlParams.join("/"));
      }}
    >
      {/* {lang==='en'?<img src='../''GE':'EN'} */}
      <img src={lang === "en" ? kaFlag : enFlag} width={30} />
    </div>
  );
};
