import React from 'react';
import {Link, useParams} from 'react-router-dom'
import BlogSidebar from '../BlogSidebar'
import VideoModal from '../../components/ModalVideo'

import blogs from '../../api/blogs'

 const not_found = {
    "ka":"ბლოგები არ მოიძებნა",
    "en":"404 not found",

 }
 const translate = {
    ka:{
        readMore:"მეტის ნახვა...",
    },
     en: {
            readMore: "Read More...",
     }
 }

const BlogList = (props) => {
    const { lang = "ka" } = useParams();

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <section className="wpo-blog-pg-section section-padding">
            <div className="container">

              {/* <div style={{minHeight:100}}>
                   <h5>
                       {
                           not_found[lang]
                       }
                   </h5>
               </div>*/}


                <div className="row">
                    <div className={`col col-lg-12 col-12 ${props.blRight}`}>
                        <div className="wpo-blog-content">
                            {blogs.slice(0,4).reverse().map((blog, bitem) => (
                                <div className={`post  ${blog.blClass}`}key={bitem}>
                                    <div className="entry-media video-holder entry-meta" >
                                        <img src={blog.blogSingleImg} alt=""/>
                                    </div>
                                    {/*<div className="entry-meta">
                                        <ul>
                                            <li><i className="fi flaticon-user"></i> By <Link onClick={ClickHandler} to={`/blog-single/${blog.id}`}>{blog.authorTitle}</Link> </li>
                                            <li><i className="fi flaticon-comment-white-oval-bubble"></i> Comments {blog.comment} </li>
                                            <li><i className="fi flaticon-calendar"></i> {blog.create_at}</li>
                                        </ul>
                                    </div>*/}
                                    <div className="entry-details">
                                        <h3><Link onClick={ClickHandler} to={`/blog/${blog.id.concat("-",encodeURIComponent(blog.title).split(" ").join("-"))}`}>{blog.title}</Link></h3>
                                        <p>{blog.short}</p>
                                        <Link onClick={ClickHandler} to={`/blog/${blog.id.concat("-",(blog.title).split(" ").join("-"))}`} className="read-more">{
                                            translate[lang].readMore
                                        }</Link>
                                    </div>
                                </div>
                            ))}

                           {/* <div className="pagination-wrapper pagination-wrapper-left">
                                <ul className="pg-pagination">
                                    <li>
                                        <Link to="/blog-left-sidebar" aria-label="Previous">
                                            <i className="fi ti-angle-left"></i>
                                        </Link>
                                    </li>
                                    <li className="active"><Link to="/blog-left-sidebar">1</Link></li>
                                    <li><Link to="/blog-left-sidebar">2</Link></li>
                                    <li><Link to="/blog-left-sidebar">3</Link></li>
                                    <li>
                                        <Link to="/blog-left-sidebar" aria-label="Next">
                                            <i className="fi ti-angle-right"></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>*/}
                        </div>
                    </div>
{/*
                    <BlogSidebar blLeft={props.blLeft}/>
*/}
                </div>

            </div>
        </section>

     )
        
}

export default BlogList;
