import React from 'react';
import Services from '../../api/service'
import { Link } from 'react-router-dom'


import { useParams } from "react-router-dom";
import { ServiceI18n } from "../../i18n/service";
import { Service2I18n } from "../../i18n/service2";


const RelatedService = () => {

    const {lang='ka'} =useParams()

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }



    return (
        <div className="wpo-service-single-item">
            <div className="wpo-service-single-title">
                {/* <h3>{Service2I18n[lang]["text4"]}</h3> */}
            </div>
            <div className="wpo-service-area">
                <div className="row align-items-center">
                    {ServiceI18n.map((sitem) => (
                        <div className="col-lg-4 col-md-6 col-12" key={sitem.id}>
                            <div className="wpo-service-item">
                                <i className={sitem.data[lang].icon}></i>
                                {/* <h2>{service.sTitle}</h2> */}
                               <ul>
                                <li><Link onClick={ClickHandler} to={`/${lang}/service-single/${sitem.id}`}>{<h2>{sitem.data[lang].sTitle}</h2>}</Link></li>
                                </ul>
                                <p>{sitem.data[lang].des2}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};
export default RelatedService;

