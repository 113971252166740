export const HeaderI18n={
    ka:{
        text1:`მთავარი `,

        text2:`ჩვენს შესახებ `,

        text3:`სერვისები`,

        text4:`პროექტი`,
        text5:`დასრულებული`,
        text6:`მიმდინარე`,

        text7:`კონტაქტი`,
        text8:"მშენებლობა",
        text9:"რემონტი",
        text10:"არქიტექტურა",
        text11:"ინტერიერის დიზაინი",
        text12:"შოპი",
        text13:"ბლოგი",

    },
    en:{
        text1:`Home  `,

        text2:`About`,

        text3:`Service`,

        text4:`Project`,
        text5:`Completed`,
        text6:`Current`,

        text7:`Contact`,
        text8:"Construction",
        text9:"Repair",
        text10:"Architecture",
        text11:"Design",
        text12:"Shop",
        text13:"Blog"
    }
}