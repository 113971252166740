import timg1 from '../images/team/img-1.jpg'
import timg2 from '../images/team/img-2.jpg'
import timg3 from '../images/team/img-3.jpg'
import timg4 from '../images/team/img-4.jpg'


const Teams = [
    {
        Id: '1',
        tImg:timg1,
        name:'Robert Mandy',
        title:'Creative Director',
    },
    {
        Id: '2',
        tImg:timg2,
        name:'Henry Bannet',
        title:'Sineor Architect',
    },
    {
        Id: '3',
        tImg:timg3,
        name:'William Watson',
        title:'Creative Director',
    },
    {
        Id: '4',
        tImg:timg4,
        name:'Lily Margerat',
        title:'Art Director',
    },
]

export default Teams;