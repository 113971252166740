import simg from "../images/service-single/arcitecur1.jpg";
import simg2 from "../images/service-single/mshenebloba.png";
import simg3 from "../images/service-single/dizaini.jpg";
import simg4 from "../images/service-single/remonti3.jpg";
import simg5 from "../images/service-single/5.jpg";
import simg6 from "../images/service-single/6.jpg";
import simg7 from "../images/service-single/7.jpg";
import simg8 from "../images/service-single/8.jpg";
import simg9 from "../images/service-single/9.jpg";
import simg10 from "../images/service-single/10.jpg";
import simg11 from "../images/service-single/11.jpg";
import simg12 from "../images/service-single/12.jpg";
import simg13 from "../images/service-single/13.jpg";
import renovation from "../images/renovation.png";

import sSingleimg1 from "../images/service-single/s1.jpg";
import sSingleimg2 from "../images/service-single/s2.jpg";

import ins1 from "../images/instragram/7.jpg";
import ins2 from "../images/instragram/8.jpg";
import ins3 from "../images/instragram/9.jpg";
import ins4 from "../images/instragram/10.jpg";
import ins5 from "../images/instragram/11.jpg";
import ins6 from "../images/instragram/12.jpg";

import dasamatebeli1 from "../images/architecture/ds1.jpg"
import dasamatebeli2 from "../images/architecture/ds2.jpg"

import dasamatebeli3 from "../images/architecture/ds3.jpg"

export const ServiceI18n = [
    {
        id: "2",

        data:{
            ka: {
                sImg: dasamatebeli2,
                // sImg: simg2,
                sTitle: "მშენებლობა",
                description:
                    " მცირე და მრავალფუნქციური საცხოვრებელი სახლების და აგარაკების მშენებლობა მაღალი ხარისხის მასალების გამოყენებით და ოპტიმალური დანახარჯით            ",
                // " ინდივიდუალური საცხოვრებელი ბინა, აგარაკი, მრავალსართულიანი საცხოვრებელი ბინა, კომერციული ობიექტები",
                des2: "კომპანია გთავაზობთ სამშენებლო სამუშაოებს ნებისმიერი სირთულის პროექტისათვის. მომსახურება მოიცავს მშენებლობის სრულ ციკლს: პროექტირება, სამშენებლო ნებართვის აღება, მშენებლობა, ინტერიერის და ლანდშაფტის დიზაინი, რემონტი და ეზოს მოწყობა.",
                des3: (
                    <div>
                        <p>
                            დეკოლაინის სამშენებლო გუნდი აერთიანებს მაღალკვალიფიციურ ინჟინრებს, პროექტ მენეჯერებს, ფინანსისტებს, აქვს უსაფრთხოების დაცვის უმაღლესი სტანდარტი, 24 საათიანი ვიდეომონიტორინგის საშუალება, ტექნიკური მხარდაჭერა, ხარისხის კონტროლი.
                        </p>
                        <ul>
                            <li>კერძო ბინა</li>
                            <li>აგარაკი</li>
                            <li>ეკომერციული ობიექტი</li>
                        </ul>
                    </div>
                ),
                icon: "fi flaticon-blueprint",
                ssImg1: sSingleimg1,
                ssImg2: sSingleimg2,
                sinst: ins2,
            },
            en: {

                sImg: simg2,
                sTitle: "Construction",
                description:
                    "Individual residential apartment, country house, multi-storey residential apartment, commercial facilities",
                des2: "The company offers construction works for projects of any complexity. Services include the full cycle of construction: design, obtaining building permits, construction, interior and landscape design, renovation and landscaping.",
                des3: (
                    <div>
                        <p>
                            Decoline's construction team unites highly qualified engineers, project managers, financiers, has the highest standard of safety protection, 24-hour video monitoring facility, technical support and quality control.
                        </p>
                        <ul>
                            <li>Private apartment</li>
                            <li>Country house</li>
                            <li>commercial object</li>
                        </ul>
                    </div>
                ),
                icon: "fi flaticon-blueprint",
                ssImg1: sSingleimg1,
                ssImg2: sSingleimg2,
                sinst: ins2,
            },
        }



    },
    {
        id: "5",

        data:{
            ka: {
                sImg: dasamatebeli1,
                // sImg: simg4,
                sTitle: "რემონტი",
                description:
                    "სარემონტო სამუშაოები, როგორც ინდივიდუალური ბინის, ასევე კომერციული ობიექტისთვის",
                // "ნებისმიერი ტიპის კომერციულ და არაკომერციულ ობიექტზე სრული სარემონტო მომსახურება: შავი, თეთრი და მწვანე კარკასი",
                des2: "სარემონტო სამუშაოები, როგორც ინდივიდუალური ბინის, ასევე კომერციული ობიექტისთვის",
                des3: (
                    <div>
                        <p>
                            <h3>სარემონტო მომსახურება მოიცავს შემდეგ მიმართულებებს. :</h3>
                        </p>
                        <ul>
                            <li>დემონტაჟი</li>
                            <li>შენება (აგურით/ბლოკით)</li>
                            <li>საიზოლაციო სამუშაოები</li>
                            <li>ელექტროგაყვანილობა და ვენტილაცია</li>
                            <li>წყალმომარაგება</li>
                            <li>გათბობა/გაგრილება</li>
                            <li>ლესვა: გაჯით, ქვიშა&ცემენტით, როტბანდით</li>
                            <li>მოჭიმული იატაკი: თვითსწორებადი, სტანდარტული</li>
                            <li>სამალიარო სამუშაოები: დაშპაკვლა, შეღებვა, შპალერის გაკვრა</li>
                            <li>კაფელ-მეტლახის სამუშაოები</li>
                            <li>მეტლახის, მდფ-ის, პლასტმასისა და ხის პლინტუსის გაკვრა</li>
                            <li>ლამინატისა და პარკეტის მონტაჟი; იატაკის მოხვეწა (ციკლოვკა)</li>
                            <li>საბრიზგი სამუშაოები</li>
                            <li>
                                თაბაშირ-მუყაოს ფილების სამონტაჟო სამუშაოები: ჭერები, ტიხრები
                            </li>
                            <li>
                                {" "}
                                ფრანგული გასაჭიმი ჭერის მონტაჟი; (ბარისოლი); ამსტრონგის ჭერის
                                მონტაჟი
                            </li>
                            <li>მეტალის, MDF-ისა და ხის კარებების მონტაჟი</li>
                            <li>მეტალის ნაკეთობების ღებვა; ვიტრაჟების მოწყობა</li>
                            <li>მეტალო-პლასტმასის სამონტაჟო სამუშაოები</li>
                            <li>სამშენებლო ნაგვის გატანა; დალაგება, დასუფთავება.</li>
                        </ul>
                    </div>
                ),
                icon: "fi flaticon-armchair",
                ssImg1: sSingleimg1,
                ssImg2: sSingleimg2,
                sinst: ins4,
            },
            en: {
                sImg: simg4,
                sTitle: "Repair",
                description:
                    "Full repair/renovation service for any type of commercial and non-commercial object: Black, white and green frames",
                des2: "full repair service for any type of commercial and non-commercial object: black, white and green frames",
                des3: (
                    <div>
                        <p>
                            <h3>Repair services include the following directions. :</h3>
                        </p>
                        <ul>
                            <li> Dismantling</li>
                            <li>building (brick/block)</li>
                            <li>insulating works</li>
                            <li>electrical wiring and ventilation</li>
                            <li>water supply</li>
                            <li>heating/cooling</li>
                            <li>Lesva: with sand, sand & cement, Rotband</li>
                            <li>Stretched floor: self-correcting, standard</li>
                            <li>Painting works: plastering, painting, wallpapering</li>
                            <li>tile-metal works</li>
                            <li>fastening of metal, MDF, plastic and wooden plinth</li>
                            <li>
                                installation of laminate and parquet; polishing the floor
                                (cycling)
                            </li>
                            <li>Spraying works</li>
                            <li>
                                Installation works of plasterboard tiles: ceilings, partitions
                            </li>
                            <li>
                                French stretch ceiling installation; (Barisol); Armstrong ceiling
                                installation
                            </li>
                            <li>installation of metal, MDF and wooden doors</li>
                            <li>
                                Painting of metal products; Arrangement of stained glass windows
                            </li>
                            <li>metal-plastic installation works</li>
                            <li>removal of construction waste; Sorting, cleaning.</li>
                        </ul>
                    </div>
                ),
                icon: "fi flaticon-armchair",
                ssImg1: sSingleimg1,
                ssImg2: sSingleimg2,
                sinst: ins4,
            },
        }



    },
 {
  id: "1",

  data:{
    ka: {
      
     
      sImg: simg,
      sTitle: "არქიტექტურა",
      description:
      "სრული არქიტექტურული პროექტი , როგორც საცხოვრებელი სახლების, ასევე სხვა დანიშნულების შენობა ნაგებობების, პროექტის შესათანხმებლად საჭირო დოკუმენტაცია",
        // "ურბანული დაგეგმარება, სტრუქტურული ინჟინერია, MEP ინჟინერია, სანებართვო დოკუმენტაციის მოზადება და სრული არქიტექტურული მომსახურება.",
      des2: "თანამედროვე არქიტექტურაში მნიშვნელოვანია კომპლექსური მიდგომა და ინდივიდუალიზმის შენარჩუნება. დეკოლაინის პორტფელი მოიცავს ათასობით წარმატებულ პროექტს: კერძო საცხოვრებელი ბინა, ინდივიდუალური სახლი, სხვადასხვა კომპანიის საოფისე თუ საწარმოო ფართი , მრავალსართულიანი საცხოვრებელი სახლების მშენებლობა. ამჟამად, მიმდინარეობს მრავალსართულიანი საცხოვრებელი კორპუსის მშენებლობა  და კერძო ინდივიდუალური საცხოვრებელი ბინების მშენებლობა თბილისის შემოგარენში.",
      des3: "დეკოლაინის ექსპერტული დამოკიდებულება საქმისადმი და შესრულებული პროექტების რაოდენობა განაპირობებს სანდოობის მაღალ ხარისხსა და რეპუტაციას, რომლის გამოც, კომპანია სამშენებლო ბაზარზე, წლებია, ლიდერ პოზიციებზე იმყოფება.",
      icon: "fi flaticon-planning",

      ssImg1: sSingleimg1,
      ssImg2: sSingleimg2,
      sinst: ins1,
      },
    en: { 
      
    sImg: simg,
    sTitle: "Architecture",
    description:
      "urban planning, structural engineering, MEP engineering, preparation of the  permitting document and full architectural services.",
    des2: "In modern architecture, it is important to maintain an individual and complex approach. Decoline's portfolio includes thousands of successful projects: Private residential apartments, individual houses, offices or production spaces for various companies, construction of multi-storey residential houses. Currently, the company is working on the construction of a multi-storey residential building and the construction of private individual residential apartments in Tbilisi surroundings .",
    des3: "Decoline's expert attitude to work and the number of completed projects lead to a high degree of reliability and reputation, due to which the company has been on a leading position in the construction industry for years.",
    icon: "fi flaticon-planning",

    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
    sinst: ins1,},
  }



  },




    {
      id: "3",
    
      data:{
        ka: {
          sImg: dasamatebeli3,
          // sImg: simg3,
              sTitle: "ინტერიერის დიზაინი",
              description:
              "ინტერიერის ინდივიდუალური დიზაინის პროექტი, მის შესასრულებლად საჭირო ყველა ეტაპი.",
                // "ინტერიერისა და ექსტერიერის, განათების, ლანდშაფტისა და არქიტექტურულ დიზაინი",
              des2: "დიზაინის შექმნისას მნიშვნელოვანია პროფესიონალური ხედვა, ექსპერტული გამოცდილება და ინდივიდუალური მიდგომები. თითოეულ პროექტზე მუშაობისას, განსაკუთრებული ყურადღება ეთმობა მის ფუნქციონალობას. მიგვაჩნია, რომ მხოლოდ თანამედროვე სტილი მომხმარებლის მოთხოვნებს სრულად ვერ დააკმაყოფილებს, შესაბამისად, მოდერნისტულ გადაწყვეტილებებთან ერთად, ვინარჩუნებთ ტრადიციული მიდგომებსა და ხედვებს.",
              des3: (
                <div>
                  <p>
                  ჩვენი მთავარი გამოწვევაა ისეთი დიზაინის შექმნა, რომელიც დამკვეთისთვის გახდება კომფორტისა და სრულყოფილების გარანტი.
                  </p>
                  <p>
                    <h4>დიზაინერული მომსახურება მოიცავს:</h4>
                  </p>
                  <ul>
                    <li>ინტერიერის დიზაინი</li>
                    <li>ექსტერიერის დიზაინი</li>
                    <li>ლანდშაფტის დიზაინი</li>
                    <li>განათების დიზაინი</li>
                  </ul>
                </div>
              ),
              icon: "fi flaticon-double-bed",
              ssImg1: sSingleimg1,
              ssImg2: sSingleimg2,
              sinst: ins3,
          },
        en: { 
          sImg: simg3,
          sTitle: "Design",
      description:
        "Interior and exterior, lighting, landscape and architectural design",
      des2: "Professional vision, expert experience and individual approaches are important when creating a design. When working on each project, special attention is paid to its functionality. We believe that only the modern style cannot fully satisfy the customer's needs, therefore, together with the modernist solutions, we preserve the traditional approaches and visions.  ",
      des3: (
        <div>
          <p>
            Our main challenge is to create such a design, which will become a
            guarantee of comfort and perfection for the customer.
          </p>
          <p>
            <h4>Design services include:</h4>
          </p>
          <ul>
            <li>interior design</li>
            <li>Exterior design</li>
            <li>Landscape design</li>
            <li>Lighting design</li>
          </ul>
        </div>
      ),
      icon: "fi flaticon-double-bed",
      ssImg1: sSingleimg1,
      ssImg2: sSingleimg2,
      sinst: ins3,
      },
      }
    
    
    
      },
      {
        id: "4",
      
        data:{
          ka: {
            
            sImg: simg2,
                sTitle: "ექსტერიერის დიზაინი",
                description:
                  "ექსტერიერის ინდივიდუალური დიზაინის პროექტი, მის შესასრულებლად საჭირო ყველა ეტაპი",
                des2: "დიზაინის შექმნისას მნიშვნელოვანია პროფესიონალური ხედვა, ექსპერტული გამოცდილება და ინდივიდუალური მიდგომები. თითოეულ პროექტზე მუშაობისას, განსაკუთრებული ყურადღება ეთმობა მის ფუნქციონალობას. მიგვაჩნია, რომ მხოლოდ თანამედროვე სტილი მომხმარებლის მოთხოვნებს სრულად ვერ დააკმაყოფილებს, შესაბამისად, მოდერნისტულ გადაწყვეტილებებთან ერთად, ვინარჩუნებთ ტრადიციული მიდგომებსა და ხედვებს.",
                des3: (
                  <div>
                    <p>
                    ჩვენი მთავარი გამოწვევაა ისეთი დიზაინის შექმნა, რომელიც დამკვეთისთვის გახდება კომფორტისა და სრულყოფილების გარანტი.
                    </p>
                    <p>
                      <h4>დიზაინერული მომსახურება მოიცავს:</h4>
                    </p>
                    <ul>
                      <li>ინტერიერის დიზაინი</li>
                      <li>ექსტერიერის დიზაინი</li>
                      <li>ლანდშაფტის დიზაინი</li>
                      <li>განათების დიზაინი</li>
                    </ul>
                  </div>
                ),
                icon: "fi flaticon-double-bed",
                ssImg1: sSingleimg1,
                ssImg2: sSingleimg2,
                sinst: ins3,
            },
          en: { 
            sImg: simg3,
            sTitle: "Design",
        description:
          "Interior and exterior, lighting, landscape and architectural design",
        des2: "Professional vision, expert experience and individual approaches are important when creating a design. When working on each project, special attention is paid to its functionality. We believe that only the modern style cannot fully satisfy the customer's needs, therefore, together with the modernist solutions, we preserve the traditional approaches and visions.  ",
        des3: (
          <div>
            <p>
              Our main challenge is to create such a design, which will become a
              guarantee of comfort and perfection for the customer.
            </p>
            <p>
              <h4>Design services include:</h4>
            </p>
            <ul>
              <li>interior design</li>
              <li>Exterior design</li>
              <li>Landscape design</li>
              <li>Lighting design</li>
            </ul>
          </div>
        ),
        icon: "fi flaticon-double-bed",
        ssImg1: sSingleimg1,
        ssImg2: sSingleimg2,
        sinst: ins3,
        },
        }
      
      
      
        },






  // ka: [
  //   {
  //     Id: "1",
  //     sImg: simg,
  //     sTitle: "არქიტექტურა",
  //     description:
  //       "ურბანული დაგეგმარება, სტრუქტურული ინჟინერია, MEP ინჟინერია, სანებართვო დოკუმენტაციის მოზადება და სრული არქიტექტურული მომსახურება.",
  //     des2: "თანამედროვე არქიტექტურაში მნიშვნელოვანია კომპლექსური მიდგომა და ინდივიდუალიზმის შენარჩუნება. დეკოლაინის პორტფელი მოიცავს ათასობით წარმატებულ პროექტს: კერძო საცხოვრებელი ბინა, ინდივიდუალური სახლი, სხვადასხვა კომპანიის საოფისე თუ საწარმოო ფართი , მრავალსართულიანი საცხოვრებელი სახლების მშენებლობა. ამჟამად, მიმდინარეობს მრავალსართულიანი საცხოვრებელი კორპუსის მშენებლობა ლისზე და კერძო ინდივიდუალური საცხოვრებელი ბინების მშენებლობა თბილისის შემოგარენში.",
  //     des3: "დეკოლაინის ექსპერტული დამოკიდებულება საქმისადმი და შესრულებული პროექტების რაოდენობა განაპირობებს სანდოობის მაღალ ხარისხსა და რეპუტაციას, რომლის გამოც, კომპანია სამშენებლო ბაზარზე, წლებია, ლიდერ პოზიციებზე იმყოფება.",
  //     icon: "fi flaticon-planning",

  //     ssImg1: sSingleimg1,
  //     ssImg2: sSingleimg2,
  //     sinst: ins1,
  //   },
  //   {
  //     Id: "2",
  //     sImg: simg2,
  //     sTitle: "მშენებლობა",
  //     description:
  //       "ინდივიდუალური საცხოვრებელი ბინა, აგარაკი, მრავალსართულიანი საცხოვრებელი ბინა, კომერციული ობიექტები",
  //     des2: "კომპანია გთავაზობთ სამშენებლო სამუშაოებს ნებისმიერი სირთულის პროექტისათვის. მომსახურება მოიცავს მშენებლობის სრულ ციკლს: პროექტირება, სამშენებლო ნებართვის აღება, მშენებლობა, ინტერიერის და ლანდშაფტის დიზაინი, რემონტი და ეზოს მოწყობა.",
  //     des3: (
  //       <div>
  //         <p>
  //           დეკოლაინის სამშენებლო სექტორი აერთიანებს მაღალკვალიფიციურ ინჟინრებს,
  //           პროექტ მენეჯერებს, ფინანსისტებს, აქვს უსაფრთხოების დაცვის უმაღლესი
  //           სტანდარტი, 24 საათიანი ვიდეომონიტორინგის საშუალება, ტექნიკური
  //           მხარდაჭერა, ხარისხის კონტროლი.
  //         </p>
  //         <ul>
  //           <li>კერძო ბინა</li>
  //           <li>აგარაკი</li>
  //           <li>ეკომერციული ობიექტი</li>
  //         </ul>
  //       </div>
  //     ),
  //     icon: "fi flaticon-blueprint",
  //     ssImg1: sSingleimg1,
  //     ssImg2: sSingleimg2,
  //     sinst: ins2,
  //   },
  //   {
  //     Id: "3",
  //     sImg: simg3,
  //     sTitle: "დიზაინი",
  //     description:
  //       "ინტერიერისა და ექსტერიერის, განათების, ლანდშაფტისა და არქიტექტურულ დიზაინი",
  //     des2: "დიზაინის შექმნისას მნიშვნელოვანია პროფესიონალური ხედვა, ექსპერტული გამოცდილება და ინდივიდუალური მიდგომები. თითოეულ პროექტზე მუშაობისას, განსაკუთრებული ყურადღება ეთმობა მის ფუნქციონალობას. მიგვაჩნია, რომ მხოლოდ თანამედროვე სტილი მომხმარებლის მოთხოვნილებებს სრულად ვერ დააკმაყოფილებს, შესაბამისად, მოდერნისტულ გადაწყვეტილებებთან ერთად, ვინარჩუნებთ ტრადიციული მიდგომებსა და ხედვებს.   ",
  //     des3: (
  //       <div>
  //         <p>
  //           ჩვენი მთავარი გამოწვევაა ისეთი დიზაინის შექმნა, რომელიც
  //           დამკვეთისთვის გახდება კომფორტისა და სრულყოფილების გარანტი.
  //         </p>
  //         <p>
  //           <h4>დიზაინერული მომსახურება მოიცავს:</h4>
  //         </p>
  //         <ul>
  //           <li>ინტერიერის დიზაინი</li>
  //           <li>ექსტერიერის დიზაინი</li>
  //           <li>ლანდშაფტის დიზაინი</li>
  //           <li>განათების დიზაინი</li>
  //         </ul>
  //       </div>
  //     ),
  //     icon: "fi flaticon-double-bed",
  //     ssImg1: sSingleimg1,
  //     ssImg2: sSingleimg2,
  //     sinst: ins3,
  //   },
  //   {
  //     Id: "4",
  //     sImg: simg4,
  //     sTitle: "რემონტი",
  //     description:
  //       "ნებისმიერი ტიპის კომერციულ და არაკომერციულ ობიექტზე სრული სარემონტო მომსახურება: შავი, თეთრი და მწვანე კარკასი",
  //     des2: "ნებისმიერი ტიპის კომერციულ და არაკომერციულ ობიექტზე სრული სარემონტო მომსახურება: შავი, თეთრი და მწვანე კარკასი",
  //     des3: (
  //       <div>
  //         <p>
  //           <h3>სარემონტო მომსახურება მოიცავს შემდეგ მიმართულებებს. :</h3>
  //         </p>
  //         <ul>
  //           <li>დემონტაჟი</li>
  //           <li>შენება (აგურით/ბლოკით)</li>
  //           <li>საიზოლაციო სამუშაოები</li>
  //           <li>ელექტროგაყვანილობა და ვენტილაცია</li>
  //           <li>წყალმომარაგება</li>
  //           <li>გათბობა/გაგრილება</li>
  //           <li>ლესვა: გაჯით, ქვიშა&ცემენტით, როტბანდით</li>
  //           <li>მოჭიმული იატაკი: თვითსწორებადი, სტანდარტული</li>
  //           <li>სამალიარო სამუშაოები: დაშპაკვლა, შეღებვა, შპალერის გაკვრა</li>
  //           <li>კაფელ-მეტლახის სამუშაოები</li>
  //           <li>მეტლახის, მდფ-ის, პლასტმასისა და ხის პლინტუსის გაკვრა</li>
  //           <li>ლამინატისა და პარკეტის მონტაჟი; იატაკის მოხვეწა (ციკლოვკა)</li>
  //           <li>საბრიზგი სამუშაოები</li>
  //           <li>
  //             თაბაშირ-მუყაოს ფილების სამონტაჟო სამუშაოები: ჭერები, ტიხრები
  //           </li>
  //           <li>
  //             {" "}
  //             ფრანგული გასაჭიმი ჭერის მონტაჟი; (ბარისოლი); ამსტრონგის ჭერის
  //             მონტაჟი
  //           </li>
  //           <li>მეტალის, MDF-ისა და ხის კარებების მონტაჟი</li>
  //           <li>მეტალის ნაკეთობების ღებვა; ვიტრაჟების მოწყობა</li>
  //           <li>მეტალო-პლასტმასის სამონტაჟო სამუშაოები</li>
  //           <li>სამშენებლო ნაგვის გატანა; დალაგება, დასუფთავება.</li>
  //         </ul>
  //       </div>
  //     ),
  //     icon: "fi flaticon-armchair",
  //     ssImg1: sSingleimg1,
  //     ssImg2: sSingleimg2,
  //     sinst: ins4,
  //   },
    
    
  //   // {
  //   //     Id: '5',
  //   //     sImg:simg5,
  //   //     sTitle: 'Complete Decoration',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     icon:'fi flaticon-furniture',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins5,
  //   // },
  //   // {
  //   //     Id: '6',
  //   //     sImg:simg6,
  //   //     sTitle: 'Exterior Design',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     icon:'fi flaticon-interior-design',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins6,
  //   // },
  //   // {
  //   //     Id: '7',
  //   //     sImg:simg7,
  //   //     sTitle: 'Interior Design',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     icon:'fi flaticon-interior-design',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins6,
  //   // },
  //   // {
  //   //     Id: '8',
  //   //     sImg:simg8,
  //   //     sTitle: 'Room Decoration',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins6,
  //   // },
  //   // {
  //   //     Id: '9',
  //   //     sImg:simg9,
  //   //     sTitle: 'Planning',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins6,
  //   // },
  //   // {
  //   //     Id: '10',
  //   //     sImg:simg10,
  //   //     sTitle: 'Lighting',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins6,
  //   // },
  //   // {
  //   //     Id: '11',
  //   //     sImg:simg11,
  //   //     sTitle: 'Interior Design',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins6,
  //   // },
  //   // {
  //   //     Id: '12',
  //   //     sImg:simg12,
  //   //     sTitle: 'Room Decoration',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins6,
  //   // },
  //   // {
  //   //     Id: '13',
  //   //     sImg:simg13,
  //   //     sTitle: 'Planning',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins6,
  //   // },
  // ],

  // en: [
  //   // {
  //   //     text1: "LEARN MORE",
  //   // },

  //   {
  //     Id: "1",
  //     sImg: simg,
  //     sTitle: "Architecture",
  //     description:
  //       "urban planning, structural engineering, MEP engineering, prepearing permitting document and full architectural services.",
  //     des2: "In modern architecture, it is important to maintain a complex approach and individualism. Decoline's portfolio includes thousands of successful projects: private residential apartment, individual house, office or production space of various companies, construction of multi-storey residential houses. Currently, the construction of a multi-storey residential building on Lisi and the construction of private individual residential apartments in the outskirts of Tbilisi are underway.",
  //     des3: "Decoline's expert attitude to work and the number of completed projects lead to a high degree of reliability and reputation, due to which the company has been in a leading position in the construction market for years.",
  //     icon: "fi flaticon-planning",

  //     ssImg1: sSingleimg1,
  //     ssImg2: sSingleimg2,
  //     sinst: ins1,
  //   },
  //   {
  //     Id: "2",
  //     sImg: simg2,
  //     sTitle: "Construction",
  //     description:
  //       "individual residential apartment, country house, multi-storey residential apartment, commercial facilities",
  //     des2: "The company offers construction works for projects of any complexity. Services include the full cycle of construction: design, obtaining building permits, construction, interior and landscape design, renovation and landscaping.",
  //     des3: (
  //       <div>
  //         <p>
  //           Decoline's construction sector unites highly qualified engineers,
  //           project managers, financiers, has the highest standard of safety
  //           protection, 24-hour video monitoring facility, technical support,
  //           quality control.
  //         </p>
  //         <ul>
  //           <li>Private apartment</li>
  //           <li>Country house</li>
  //           <li>commercial object</li>
  //         </ul>
  //       </div>
  //     ),
  //     icon: "fi flaticon-blueprint",
  //     ssImg1: sSingleimg1,
  //     ssImg2: sSingleimg2,
  //     sinst: ins2,
  //   },
  //   {
  //     Id: "3",
  //     sImg: simg3,
  //     sTitle: "Design",
  //     description:
  //       "interior and exterior, lighting, landscape and architectural design",
  //     des2: "Professional vision, expert experience and individual approaches are important when creating a design. When working on each project, special attention is paid to its functionality. We believe that only the modern style cannot fully satisfy the customer's needs, therefore, together with the modernist solutions, we preserve the traditional approaches and visions.  ",
  //     des3: (
  //       <div>
  //         <p>
  //           Our main challenge is to create such a design, which will become a
  //           guarantee of comfort and perfection for the customer.
  //         </p>
  //         <p>
  //           <h4>Design services include:</h4>
  //         </p>
  //         <ul>
  //           <li>interior design</li>
  //           <li>Exterior design</li>
  //           <li>Landscape design</li>
  //           <li>Lighting design</li>
  //         </ul>
  //       </div>
  //     ),
  //     icon: "fi flaticon-double-bed",
  //     ssImg1: sSingleimg1,
  //     ssImg2: sSingleimg2,
  //     sinst: ins3,
  //   },
  //   {
  //     Id: "4",
  //     sImg: simg4,
  //     sTitle: "Repair",
  //     description:
  //       "full repair service for any type of commercial and non-commercial object: black, white and green frames",
  //     des2: "full repair service for any type of commercial and non-commercial object: black, white and green frames",
  //     des3: (
  //       <div>
  //         <p>
  //           <h3>Repair services include the following directions. :</h3>
  //         </p>
  //         <ul>
  //           <li> Dismantling</li>
  //           <li>building (brick/block)</li>
  //           <li>insulating works</li>
  //           <li>electrical wiring and ventilation</li>
  //           <li>water supply</li>
  //           <li>heating/cooling</li>
  //           <li>Lesva: with sand, sand & cement, Rotband</li>
  //           <li>Stretched floor: self-correcting, standard</li>
  //           <li>Painting works: plastering, painting, wallpapering</li>
  //           <li>tile-metal works</li>
  //           <li>fastening of metal, MDF, plastic and wooden plinth</li>
  //           <li>
  //             installation of laminate and parquet; polishing the floor
  //             (cycling)
  //           </li>
  //           <li>Spraying works</li>
  //           <li>
  //             Installation works of plasterboard tiles: ceilings, partitions
  //           </li>
  //           <li>
  //             French stretch ceiling installation; (Barisol); Armstrong ceiling
  //             installation
  //           </li>
  //           <li>installation of metal, MDF and wooden doors</li>
  //           <li>
  //             Painting of metal products; Arrangement of stained glass windows
  //           </li>
  //           <li>metal-plastic installation works</li>
  //           <li>removal of construction waste; Sorting, cleaning.</li>
  //         </ul>
  //       </div>
  //     ),
  //     icon: "fi flaticon-armchair",
  //     ssImg1: sSingleimg1,
  //     ssImg2: sSingleimg2,
  //     sinst: ins4,
  //   },
  //   // {
  //   //     Id: '5',
  //   //     sImg:simg5,
  //   //     sTitle: 'Complete Decoration',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     icon:'fi flaticon-furniture',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins5,
  //   // },
  //   // {
  //   //     Id: '6',
  //   //     sImg:simg6,
  //   //     sTitle: 'Exterior Design',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     icon:'fi flaticon-interior-design',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins6,
  //   // },
  //   // {
  //   //     Id: '7',
  //   //     sImg:simg7,
  //   //     sTitle: 'Interior Design',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     icon:'fi flaticon-interior-design',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins6,
  //   // },
  //   // {
  //   //     Id: '8',
  //   //     sImg:simg8,
  //   //     sTitle: 'Room Decoration',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins6,
  //   // },
  //   // {
  //   //     Id: '9',
  //   //     sImg:simg9,
  //   //     sTitle: 'Planning',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins6,
  //   // },
  //   // {
  //   //     Id: '10',
  //   //     sImg:simg10,
  //   //     sTitle: 'Lighting',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins6,
  //   // },
  //   // {
  //   //     Id: '11',
  //   //     sImg:simg11,
  //   //     sTitle: 'Interior Design',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins6,
  //   // },
  //   // {
  //   //     Id: '12',
  //   //     sImg:simg12,
  //   //     sTitle: 'Room Decoration',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins6,
  //   // },
  //   // {
  //   //     Id: '13',
  //   //     sImg:simg13,
  //   //     sTitle: 'Planning',
  //   //     description:'Lacus, etiam sed est eu tempus need Temer diam congue laoret cursus nam nunc fam interdum Viverra.',
  //   //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
  //   //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
  //   //     ssImg1:sSingleimg1,
  //   //     ssImg2:sSingleimg2,
  //   //     sinst:ins6,
  //   // },
  // ],
];
